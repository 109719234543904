<template>
  <div>
    <b-tabs
      v-model="tabActive"
      lazy
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-2"
      @activate-tab="onActiveTab"
    >
      <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.name" lazy>
        <div>
          <div
            v-if="tab.key === 'coin-history' || tab.key === 'wallpaper-history'"
            class="d-flex flex-column flex-sm-row justify-content-end align-items-end mb-2"
          >
            <p class="m-0 mb-50">เลือกเดือน-ปี</p>
            <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
              <v-select
                v-model="dateSelected"
                :options="dateOptions"
                :clearable="false"
                :disabled="pageLoading"
                :get-option-label="option => $date(option).format('MM-YYYY')"
              />
            </b-card>
          </div>
          <component
            :is="tab.component"
            :key-tab="tab.key"
            :date-selected="dateSelected"
            :page-loading="pageLoading"
            :user-id="userId"
            :date-select-options="dateOptions"
            @changeDateSelect="onChangeDateSelect"
            @changPageLoading="updatePageLoading"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  data() {
    return {
      tabs: [
        {
          key: 'general',
          name: 'แผงควบคุม',
          component: () => import('./components/v2/TabUserGeneral.vue'),
        },
        {
          key: 'coin-history',
          name: 'ประวัติการเติมเครดิต',
          component: () => import('./components/v2/TabUserDynamicTable.vue'),
        },
        {
          key: 'wallpaper-history',
          name: 'ข้อมูลการซื้อรูป',
          component: () => import('./components/v2/TabUserDynamicTable.vue'),
        },
        {
          key: 'refund-history',
          name: 'ประวัติการขอคืนเงิน',
          component: () => import('./components/v2/TabUserDynamicTable.vue'),
        },
      ],
      tabActive: 0,
      dateSelected: null,
      dateOptions: [],
      pageLoading: false,
      userId: null,
    }
  },
  async created() {
    const { query, params } = this.$route
    if (query.tab) {
      const idx = this.tabs.findIndex(v => v.key === query.tab)
      if (idx >= 0) {
        this.tabActive = idx
      } else {
        this.$router.replace({ ...this.$route, query: { tab: this.tabs[0].key } })
      }
    }

    if (!params.userId) return
    this.userId = params.userId
    this.getDateOptions(params.userId)
  },
  methods: {
    onActiveTab(newTabIndex) {
      const tab = this.tabs[newTabIndex].key
      this.$router.replace({ ...this.$route, query: { tab } })
    },
    async getDateOptions(userId) {
      this.pageLoading = true
      const resp = await this.api.get(`api/customers/dashboard/fetch-date/${userId}`).catch(() => null)
      if (resp && Array.isArray(resp)) {
        this.dateOptions = [...resp]
        this.dateSelected = resp[0] ?? null
      }
      this.pageLoading = false
    },
    onChangeDateSelect(date) {
      this.dateSelected = date
    },
    updatePageLoading(val) {
      this.pageLoading = val
    },
  },
}
</script>

<style lang="scss" scoped></style>
